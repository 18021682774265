<template>
  <div>
    <div class="header"><img src="@/assets/images/header.jpg" width="100%"></div>
    <div class="navbar szse-navbar szse-navbar-pc">
      <nav
        class="collapse navbar-collapse bs-navbar-collapse navbar-wrap"
        role="navigation"
      >
        <div class="g-container">
          <div class="nav-box bondnav-box">
            <ul class="nav navbar-nav" style="font-family: sans-serif;">
              <li chnlcode="home" class="active">
                <a datapath="home" href="#">HOME</a>
              </li>
              <li chnlcode="listings">
                <a datapath="listings">LISTING</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="whySzse">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="whySzse">Why MOEX Blue Chip</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="standards">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="standards">Listing Standards</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="process">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="process">Listing Process</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="listFees">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="listFees">Listing Fees</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="faq">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="faq"
                              >Frequently Asked Questions</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="disclosures">
                <a datapath="disclosures">DISCLOSURE</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="announcements">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="announcements"
                              >Company Announcements</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="profiles_link">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="profiles_link"
                              >Company Profiles</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="fund">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="fund">Fund Information</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="siteMarketData">
                <a datapath="siteMarketData">MARKET DATA</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="siteMarketDatas">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Market Data</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Stocks</a>

                            <a href="#"><i></i>Funds</a>

                            <a href="#"><i></i>Quotes Lookup</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="marketStatistics">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Market Statistics</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Market Overview</a>

                            <a href="#"><i></i>Securities Summary</a>

                            <a href="#"><i></i>Sector Summary</a>

                            <a href="#"><i></i>Top Counters</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="indices">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Indices</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Indices Performance</a>

                            <a href="#"><i></i>Indices Constituent</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="publication">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Publication</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Monthly Report</a>

                            <a href="#"><i></i>Fact Book</a>

                            <a href="#"><i></i>Research</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="products">
                <a datapath="products">PRODUCTS</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="equity">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Equity</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Overview</a>

                            <a href="#"><i></i>Main Board</a>

                            <a href="#"><i></i>ChiNext</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="lefuFunds">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="lefuFunds">Fund</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="bonds">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="bonds">Bonds</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="security">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="security"
                              >Asset-Backed Securities</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="options">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Options</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Overview</a>

                            <a href="#"><i></i>CSI 300 ETF Options</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="services">
                <a datapath="services">SERVICES</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="trading">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Trading</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Overview</a>

                            <a href="#"><i></i>Trading Calendar</a>

                            <a href="#"><i></i>Trading Fees</a>

                            <a href="#"><i></i>Margin Trading</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="membership">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="membership"
                              >Membership Services</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="stockConnect_link">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="stockConnect_link"
                              >Stock Connect</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="qr_link">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="qr_link">QFII &amp; RQFII</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="dataServices">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="dataServices">Data Services</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="siteRules">
                <a datapath="rules">RULES</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="siteRule">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="siteRule">MOEX Blue Chip Rules</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="enAbout">
                <a datapath="about">ABOUT</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="enAboutOverview">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enAboutOverview">Overview</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enAboutNews">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">News</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Speeches</a>

                            <a href="#"><i></i>MOEX Blue Chip News</a>

                            <a href="#"><i></i>New Listings</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enMilestones">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enMilestones">Milestones</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enOurBrand">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enOurBrand">Our Brand</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enOrganization">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enOrganization">Organization</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enMembers">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enMembers">Members</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enCorporate">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enCorporate"
                              >Corporate Social Responsibility</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enCrossborder_link">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enCrossborder_link"
                              >Cross-border Capital Services</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enContactus">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enContactus">Contact Us</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <div class="navbar-fixed-top navmobile szse-navbar szse-navbar-mobile">
      <div class="mobile-search">
        <div class="mobile-search_container">
          <div class="mobile-search_form">
            <div class="top-search-el">
              <div
                class="search-form sh-searchhint-container"
                style="position: relative;"
              >
                <form action="" target="frameFile" id="searh_form">
                  <input
                    type="search"
                    class="mobile-search_input search-input need-repaired-placeholder"
                    placeholder="Search"
                  />
                  <input type="text" style="display: none;" />
                  <iframe name="frameFile" style="display: none;"></iframe>
                </form>
              </div>
            </div>
            <!-- <input class="mobile-search_input" type="text" name="topbarSearch" placeholder="搜索行情/公告/其他信息" /> -->
            <img
              class="mobile-search_close"
              src="@/assets/images/mobile-search_close.png"
              alt="search close"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="g-locationnav">
      <div class="banner-wrap">
          <img src="http://res.static.szse.cn/enweb/common/images/commonbannerbg.jpg">
      </div>
      <div class="g-container">
          <div class="g-banner-txt">
              <h2 class="hidden-xs">MOEX Blue Chip English</h2>
              <p>
                  <img src="http://res.static.szse.cn/common/icon/er-banneradr.png">LOCATION: 
                  <a href="./" class="CurrChnlCls">MOEX Blue Chip English</a>
              </p>
          </div>
      </div>
    </div>
    <!--207 -->
    <!-- <div class="indexbanner-wrap en-banner">
    </div> -->
    <div class="component">
      <div class="g-container">
        <div class="illustration-container">
          <img src="@/assets/images/RUSSIA_Main_Banner.png" alt="component-banner" class="illustration-image">
        </div>
        <div class="list-wrap">
            <div tabindex="0" class="c-selectex-container">    
              <div class="dropdown">        
                <a class="btn btn-default c-selectex-btn dropdown-btn" data-s-dropdown-id="#c-selectex-menu-2">           
                  <span tabindex="0" class="c-selectex-btn-text undefined" style="display: block;font-family: sans-serif;" title="MOEX Blue Chip VIPV2 Component Index">MOEX Blue Chip VIPV2 Component Index</span>           
                  <span class="glyphicon glyphicon-menu-down"></span>       
                </a>       
                <ul id="c-selectex-menu-2" class="dropdown-menu">
                  <li class="active">
                    <a title="MOEX Blue Chip VIPV2 Component Index" data-id="0" data-value="399001">MOEX Blue Chip VIPV2 Component Index</a>
                  </li>
                  <li>
                    <a title="MOEX Blue Chip Composite Index" data-id="1" data-value="399106">MOEX Blue Chip VIPV2 Composite Index</a>
                  </li>
                  <li>
                    <a title="ChiNext Index" data-id="2" data-value="399006">ChiNext Index</a>
                  </li>
                  <li>
                    <a title="MOEX Blue Chip 100 Index" data-id="3" data-value="399330">MOEX Blue Chip 100 Index</a>
                  </li>
                </ul>   
              </div> 
            </div>
            <div class="index-wrap">
                <div class="index-trend">
                    <ul class="pic-tabs">
                        <li class="active">
                            <a href="#" style="font-family: sans-serif;">Real-time</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" style="font-family: sans-serif;">Daily</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" style="font-family: sans-serif;">Weekly</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" style="font-family: sans-serif;">Monthly</a>
                        </li>
                    </ul>
                    <div class="tabs-content">
                        <div id="minutely" class="tab-panel active" _echarts_instance_="ec_1680574159124" style="-webkit-tap-highlight-color: transparent; user-select: none; background: transparent;">
                          <div style="position: relative; overflow: hidden; width: 772px; height: 283px; padding: 0px; margin: 0px; border-width: 0px; cursor: default;">
                            <canvas width="772" height="283" data-zr-dom-id="zr_0" style="position: absolute; left: 0px; top: 0px; width: 772px; height: 283px; user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); padding: 0px; margin: 0px; border-width: 0px;"></canvas>
                          </div>
                          <div></div>
                        </div>
                    </div>
                <span class="update-time">{{showDate2(this.last.createdate)}}</span></div>
                <div class="index-detail">
                        <div style="font-family: sans-serif;margin-bottom: 0px;" :class="{'title':true,'down':(this.last.change<0)?true:false,'up':(this.last.change>=0)?true:false}">{{numberFormat(this.last.last_threeall,true)}}</div>
                        <div style="font-family: sans-serif;padding-top: -0px;padding-bottom: 20px; text-align: center;font-weight: 600;"><font size="3" :color="(this.last.change0)?'#22ac38':'#db3338'">({{ numberFormat(this.last.last_twoall,true)}} / {{ numberFormat(this.last.change,true) }})</font></div>
                        <ul>
                            <li>
                                <span class="name">Prev.Close</span>
                                <span class="value">{{(this.last.threeall2)?numberFormat(this.last.threeall2,true):(this.last.threeall)?numberFormat(this.last.threeall,true):numberFormat(this.last.open,true)}}</span>
                            </li>
                            <li :class="{'open-down':(this.last.change<0)?true:false,'open-up':(this.last.change>=0)?true:false}">
                                <span class="name">Open</span>
                                <span class="value change">
                                    <span class="value-cont">
                                        <i class="icon"></i>
                                        {{numberFormat(this.last.open,true)}}
                                    </span>
                                </span>
                            </li>
                            <li :class="{'down':(this.last.change<0)?true:false,'up':(this.last.change>=0)?true:false}">
                                <span class="name">Last</span>
                                <span class="value change">
                                    <span class="value-cont">
                                        <i class="icon"></i>
                                        {{numberFormat(this.last.last_threeall,true)}}
                                    </span>
                                </span>
                            </li>
                            <!-- <li  :class="{'down':(this.last.change<0)?true:false,'up':(this.last.change>=0)?true:false}">
                                <span class="name">Change</span>
                                <span class="value change">
                                    <span class="value-cont">
                                        <i class="icon"></i>
                                        {{ numberFormat(this.last.last_twoall,true) }}
                                    </span>
                                </span>
                            </li>
                            <li style="background-color: #f7f7f7;" :class="{'down':(this.last.change<0)?true:false,'up':(this.last.change>=0)?true:false}">
                                <span class="name">Change（%）</span>
                                <span class="value change">
                                    <span class="value-cont">
                                        <i class="icon"></i>
                                        {{ numberFormat(this.last.change,true) }}
                                    </span>
                                </span>
                            </li> -->
                            <li >
                                <span class="name">Day High</span>
                                <span class="value blue">
                                  {{ numberFormat(this.last.max,true) }}
                                </span>
                            </li>
                            <li >
                                <span class="name">Day Low</span>
                                <span class="value blue">
                                  {{ numberFormat(this.last.min,true) }}
                                </span>
                            </li>
                            <li>
                                <span class="name">Volume（10Bil.）</span>
                                <span class="value">{{ numberFormat(this.last.volumn10bil,true) }}</span>
                            </li>
                            <li>
                                <span class="name">Amount（100Mil.）</span>
                                <span class="value">{{ numberFormat(this.last.volumnmil,true) }}</span>
                            </li>
                        </ul>
                    </div>
            </div><a class="loadingBtn"></a>
        </div>
      </div>
    </div>
    <div class="history" >
          <h2 style="text-align: center;font-family: sans-serif; padding-top: 20px; padding-bottom: 20px;">MOEX Blue Chip VIPV2 Component Index Historical Data</h2>
          <table
          class="tbl"
            data-test="historical-data-table"
            style="--freeze-column-width:100px;"
          >
            <thead class="datatable_head__ysELt" style="font-family: sans-serif;">
              <tr class="datatable_row__qHMpQ">
                <th class="datatable_cell__0y0eu" style="height: 50px;">
                  <div class="datatable_cell__wrapper__ddKL4">
                    <span style="color:white;">Date</span>
                  </div>
                </th>
                <th
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
                >
                  <div class="datatable_cell__wrapper__ddKL4">
                    <span style="color:white">Price</span>
                  </div>
                </th>
                <th
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
                >
                  <div class="datatable_cell__wrapper__ddKL4">
                    <span style="color:white">Open</span>
                  </div>
                </th>
                <th
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
                >
                  <div class="datatable_cell__wrapper__ddKL4">
                    <span style="color:white">High</span>
                  </div>
                </th>
                <th
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
                >
                  <div class="datatable_cell__wrapper__ddKL4">
                    <span style="color:white">Low</span>
                  </div>
                </th>
                <th
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
                >
                  <div class="datatable_cell__wrapper__ddKL4">
                    <span style="color:white">Vol</span>
                  </div>
                </th>
                <th
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
                >
                  <div class="datatable_cell__wrapper__ddKL4">
                      <span style="color:white">Change %</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="datatable_body__cs8vJ">
              <tr
                class="datatable_row__qHMpQ" style="font-family: sans-serif;"
                data-test="historical-data-table-row" v-for="(item,index) in data"
              >
                <td class="datatable_cell__0y0eu font-bold" style="text-align: center;">
                  {{ showDate(item.datetime) }}
                </td>
                <td
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz datatable_cell--up__Gt_ed"
                  dir="ltr" :style="{'text-align': 'right','color':checkColor(item.twoall)}"
                >
                <template v-if="item.threeall">
                  {{ numberFormat(item.threeall,true) }} ({{ (numberFormat(item.twoall,true)) }})
                </template>
                </td>
                <td
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz" style="text-align: right;"
                >
                  {{ numberFormat(item.open,true) }}
                </td>
                <td
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz" style="text-align: right;"
                >
                  {{ numberFormat(item.max,true) }}
                </td>
                <td
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz" style="text-align: right;"
                >
                 {{ numberFormat(item.min,true) }}
                </td>
                <td
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz" 
                  data-test="volume-cell" style="text-align: right;"
                >
                 {{ numberFormat(item.volumn,true) }}M
                </td>
                <td
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz datatable_cell--up__Gt_ed font-bold"
                  dir="ltr" :style="{'text-align': 'right','color':checkColor(item.change)}"
                >
                  {{ (item.change.toString().charAt(0) === '-')?item.change:'+'+item.change }}%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="font-family: sans-serif;padding-top: 20px;padding-bottom: 20px; justify-content: center; display: flex;">
          <img src="@/assets/images/RUSSIA_News.png" alt="component-banner" class="illustration-image2">
        </div>
        <hr>
    
    <footer class="enfooter">
      <div class="enfooter-content">
          <div class="g-container" style="font-family: sans-serif;">
              <div class="footer-navlinks hidden-xs" style="text-align: center;">
                  <a href="#">HOME</a>
                  <a href="#">DISCLOSURE</a>
                  <a href="#">MARKET DATA</a>
                  <a href="#">PRODUCTS</a>
                  <a href="#">SERVICES</a>
                  <a href="#">RULES</a>
                  <a href="#">ABOUT</a>
              </div>
          </div>
      </div>
      <div class="footer-copyright">
            <div class="g-container hidden-xs">
                <div class="footersafe-con">
                        <img id="imgConac" src="http://res.static.szse.cn/common/icon/footer-icon-1.png">
                        <img src="http://res.static.szse.cn/common/icon/footer-icon-2.png">
                        <img src="http://res.static.szse.cn/common/icon/footer-icon-3.png">
                </div>
                <div class="con" style="font-family: sans-serif;">
                    <div class="rule-tip">
                        <a class="lawspublishlink" href="#" target="_blank ">Disclaimer</a>
                            &nbsp;
                            <a class="copyright-tip" href="#">ICP05012689</a><span>©<span class="copyright-year">2023</span> MOEX Blue Chip VIPV2 Stock Exchange. All Rights Reserved</span>
                    </div>
                    <div class="ie-tip ">
                      IE9.0+，1280*889
                    </div>
                </div>
            </div>
            
            <div class="footer-copyright-mobile visible-xs ">
                <div class="footersafe-con">
                    <a href="//bszs.conac.cn/sitename?method=show&amp;id=541B4DBFF36A103AE053022819ACAC5A">
                                      <img id="imgConac" src="http://res.static.szse.cn/common/icon/footer-icon-1.png">
                                  </a>
                    <a href="http://www.sznet110.gov.cn/netalarm/index.jsp">
                                      <img src="http://res.static.szse.cn/common/icon/footer-icon-2.png">
                                  </a>
                    <a href="http://www.sznet110.gov.cn/webrecord/innernet/Welcome.jsp?bano=4403201010119">
                                      <img src="http://res.static.szse.cn/common/icon/footer-icon-3.png">
                                  </a>
                </div>
                    <div class="con"> <a class="lawspublishlink" href="#" target="_blank ">Disclaimer</a>  &nbsp;<a href="#">ICP05012689</a>
                        <br>©<span class="copyright-year">2023</span> MOEX Blue Chip VIPV2 Stock Exchange.</div>
            </div>
        </div>
      </footer> 
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";
import moment from "moment-timezone";
export default {
  name: "Home",
  mounted() {
    // this.getBackGround()
    // this.getLotto()
    // this.getBanners()
    // $("#idx_carousel").carousel({
    //   interval: 10000,
    // });
    this.date_show = moment()
      .tz("Asia/Bangkok")
      .format("MMM/DD/YYYY");
    this.getLotto();
    this.getLast();
  },
  computed: {},
  data() {
    return {
      last: [],
      status: "*Close",
      date_show: "",
      data_price: 0,
      data_prev: 0,
      chart_data: [],
      ChartPlot: "",
      data_label: [],
      data_time: [],
      first: "",
      data: [],
      picupdata:[]
    };
  },
  methods: {
    checkColor(num) {
      if (num) {
        if (Number(num) < 0) {
          return "red";
        } else {
          return "#0ea600";
        }
      }
    },
    numberFormat(x, decimal) {
      if (x) {
        if (decimal) {
          return Number(x).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        } else {
          return Number(x).toLocaleString(undefined);
        }
      }
    },
    showDate(datetime) {
      if(datetime)
      return datetime.split(" ")[0];
    },
    showDate2(datetime) {
      return moment(datetime)
        .format("DD-MM-YYYY HH:mm");
    },
    async getLotto() {
      let data = (
        await firebase
          .database()
          .ref("/huay/25")
          .orderByChild("datetime")
          .limitToLast(20)
          .once("value")
      ).val();
      data = _.orderBy(data, ["datetime"], ["desc"]);
      if (data) {
        for (const x in data) {
          const d = data[x].datetime.split(" ");
          const date = d[0].split("-");
          const time = d[1].split(":");
          const datetime = Number(
            date[0] + "" + date[1] + "" + date[2] + "" + time[0] + "" + time[1]
          );
          if (
            moment(data[x].datetime)
              .add(-4, "hours")
              .tz("Asia/Bangkok")
              .format("YYYYMMDDHHmm") >
            Number(
              moment()
                .tz("Asia/Bangkok")
                .format("YYYYMMDDHHmm")
            )
          ) {
            continue;
          }
          this.data.push(data[x]);
        }
      }
      // if (
      //   moment()
      //     .tz("Asia/Bangkok")
      //     .format("HHmm") > 700 &&
      //   moment()
      //     .tz("Asia/Bangkok")
      //     .format("HHmm") <= 1415
      // ) {
      //   const created = firebase
      //     .database()
      //     .ref("/huay/11")
      //     .orderByChild("datetime")
      //     .startAt(
      //       moment()
      //         .tz("Asia/Bangkok")
      //         .format("YYYY-MM-DD") + " 21:15"
      //     );
      //   created.on("child_changed", (snapshot) => {
      //     if (snapshot.val()) {
      //       this.data.shift();
      //       this.data.unshift(snapshot.val());
      //     }
      //   });
      //}
      //setInterval(this.getLastData, 1000);
    },
    formatDate(date) {
      const dd = moment(date)
        .tz("Asia/Bangkok")
        .format("DD");
      const year = moment(date)
        .tz("Asia/Bangkok")
        .format("YYYY");
      const month = this.convertToLaosMonth(
        moment(date)
          .tz("Asia/Bangkok")
          .format("MMMM")
      );
      return dd + " " + month + " " + year;
    },
    formatTime(date) {
      return moment(date)
        .tz("Asia/Bangkok")
        .format("HH:mm");
    },
    dateToday() {
      return moment()
        .tz("Asia/Bangkok")
        .format("dddd, DD MMMM YYYY");
    },
    async getLastData() {
      const now = moment()
        .tz("Asia/Bangkok")
        .format("HHmm");
      if (Number(now) >= 1900 && Number(now) <= 2350) {
        this.status = "*Open";
      } else {
        this.status = "*Close";
      }
    },
    async getLast() {
      //label = 6
      const price = [];
      let date;
      if (
        Number(
          moment()
            .tz("Asia/Bangkok")
            .format("HHmm")
        ) <= 1700
      ) {
        date = moment()
          .tz("Asia/Bangkok")
          .add(-1, "days")
          .format("YYYY-MM-DD");
      } else {
        date = moment()
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DD");
      }
      const n = {
          indexDetailTmp: $("#indexDetail").html(),
          minuteWrap: $("#minutely"),
          dayWrap: $("#daily"),
          weekWrap: $("#weekly"),
          monthWrap: $("#monthly"),
          trendTabs: $(".index-trend .pic-tabs"),
          code: "399001",
          charts: {},
          chartDataState: {},
      };

      firebase.firestore().collection("moexbluechip").where("date", "==", date).orderBy("updatedate", "asc").onSnapshot(async (snapshot) => {
          const data = [];
          snapshot.docChanges().forEach((change) => {
            data[change.doc.id] = change.doc.data();
            data[change.doc.id].typedata = change.type;
          });
          let min = 0;
          let max = 0;
          for (const x in data) {
            if (data[x].typedata === "added") {
              data[x].createdate = moment(data[x].createdate).add(-4,'hours').tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss');
              this.last = data[x];
              const d = moment(data[x].updatedate).add(-4,'hours').tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss');
              const createdate1 = d.split(" ")[1];
              const time = createdate1.split(":");
              const time0 = Number(time[0]).toString().padStart(2, "0");
              const amount100 = data[x].amount100mil.toString().split('.');
              const amount100mil = amount100[0]+'000';
              const amount100m2 = data[x].amount100m.toString().split('.');
              const amount100m = amount100m2[0]+amount100m2[1]+'027755.29';
              if (data[x].last_threeall) {
                this.picupdata.push([
                  time0 + ":" + time[1],
                  Number(data[x].last_threeall).toFixed(2).toString(),
                  data[x].last_twoall.toFixed(2).toString(),
                  data[x].change.toFixed(2).toString(),
                  Number(amount100mil),
                  //3697911.0,
                  Number(amount100m),
                  //5788027755.29
                ]);
              }
              min = data[x].min;
              max = data[x].max;
              // picupdata.push(
              // [
              //       "09:30",
              //       "11878.16",
              //       "-11.26",
              //       "-0.09",
              //       3697911.0,
              //       5788027755.29
              // ])
            }
          }
          const e = {
            "datetime": moment().tz('Asia/Bangkok').add(1,'hours').format('YYYY-MM-DD HH:mm'),
            "code": "0",
            "data": {
              "code": "399001",
              "name": "深证成指",
              "close": this.last.last_threeall,
              "marketTime": moment().tz('Asia/Bangkok').add(1,'hours').format('YYYY-MM-DD HH:mm'),
              "picupdata": this.picupdata,
              "releInfo": {
                "cfzqlb": "",
                "zsdm": "399001",
                "zslx": "",
                "cfzqsclb": ""
              }
            },
            "message": "成功"
          }
          var t = { data: e };
          if ($.isEmptyObject(n.charts.minuteChart)) {
              var a = new s.ui.controls.mLineChart(n.minuteWrap, t);
              n.charts.minuteChart = a;
          } else {
              n.charts.minuteChart.updateOption(t);
          }
        });
    },
  },
};
</script>

<style scoped>
.tbl {
  border-collapse: collapse;
  width:94%;
  margin-left: 3%;
  margin-ringht: 3%;
}
.tbl th,
.tbl td {
  padding: 5px;
  border: solid 1px #777;
}
.tbl th {
  background-color: #0033a0;
}
/* Container for the illustration */
.illustration-container {
  width: 100%;
  max-width: 1200px; /* Max width for large screens */
  margin: 0 auto;
  padding: 0 20px; /* Padding for smaller screens */
  text-align: center; /* Center align the illustration */
}

/* Responsive image */
.illustration-image {
  width: 100%; /* Full width for responsiveness */
  height: auto; /* Maintain aspect ratio */
  max-height: 400px; /* Optional max height to control image size on large screens */
}
.illustration-image2 {
  width: 90%; /* Full width for responsiveness */
  height: auto; /* Maintain aspect ratio */
  max-height: 400px; /* Optional max height to control image size on large screens */
}
/* Responsive text styling */
.index-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin: 20px 0;
}

.index-subtitle {
  font-size: 1.25rem;
  color: #777;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .index-title {
    font-size: 1.5rem;
  }
  .index-subtitle {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .illustration-image {
    max-width: 250px;
  }
  .index-title {
    font-size: 1.2rem;
  }
  .index-subtitle {
    font-size: 0.9rem;
  }
}
</style>
